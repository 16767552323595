import React, { Component, useState, } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { isEmpty, last, first, pick, range, inRange, orderBy, debounce, sumBy, findKey, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button, FormGroup, Input, Label, } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { startOfMonth, endOfMonth, parse as parseDate, format as formatDate } from 'date-fns';
import numeral from 'numeral';

import { dateStringToDate, } from '../../util';
import { invertedReportCodes, } from '../../shared/bugyo';
import { serial, } from '../../shared/util';
import firebase, { functions } from '../../firebase';
import { externalTypes, documentTypes, accountItemCategories, accountItemCategoriesByName, } from '../../shared/config';
import { getCategory, } from '../../shared/models/accountItem';
import { batch, getCollectionData, } from '../../shared/firebase';
import { generateRows, rowsForExport, } from '../../shared/lib/relatedCompanyBalances';
import { numberFormat, floatFormat, } from '../../util';
import { fiscalYearOfPeriod, periodOfFiscalYear, } from '../../utils';
import { fields, batchCloneFields, getExtraPartnerValues, } from '../../shared/models/relatedCompanyBalance';
import { noneSection } from '../../shared/models/section';
import { generalSegment, } from '../../shared/models/consolidationSegment';
import useDependentState from '../hooks/useDependentState';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import usePkgItems from '../hooks/usePkgItems';
import RelatedCompanyPage from '../hocs/RelatedCompanyPage';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';
import AutoLinkText from '../AutoLinkText';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import ModalButton from '../ModalButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';
import ProgressButton from '../ProgressButton';
import AmountWithExchange from '../AmountWithExchange';

const { keys, entries } = Object;
const db = firebase.firestore();
const searchFreeePartners = functions.httpsCallable('searchFreeePartners');
const searchBugyoPartners = functions.httpsCallable('searchBugyoPartners');
const searchPcaPartners = functions.httpsCallable('searchPcaPartners');
const fetchTrial = functions.httpsCallable('fetchTrial');
const fetchRawTrials = functions.httpsCallable('fetchRawTrials');
const fetchMfPartnerSectionsTrials = functions.httpsCallable('fetchMfPartnerSectionsTrials');
const fetchPcaSectionBalancesByHojo = functions.httpsCallable('fetchPcaSectionBalancesByHojo');
const accountItemCategoryNames = accountItemCategories.map(_ => _.name);

export default RelatedCompanyPage(function CompanyCashes (props) {
  const { user, relatedCompany, relatedCompanies, company, subsidiaryId, period, yearMonth, filteredYearMonths, ar, cr, periodOptions, isLockedMonth, } = props;
  const relatedCompaniesById = keyBy(relatedCompanies, 'id');
  const relatedCompaniesByName = keyBy(relatedCompanies, 'display_name');
  const relatedCompanyBalancesRef = company.ref.collection('relatedCompanyBalances');
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', subsidiaryId), [company, subsidiaryId]);
  const accountItemsById = keyBy(accountItems, 'id');
  const accountItemsByCode = keyBy(accountItems, 'shortcut_num');
  const accountItemsByName = keyBy(accountItems, 'name');
  const { items: relatedCompanyBalances } = usePkgItems(company, relatedCompany, subsidiaryId, accountItems, 'relatedCompanyBalances', ['opponentCompanyId', 'sectionId'], 'amount', [], filteredYearMonths);
  const relatedCompanyBalancesById = keyBy(relatedCompanyBalances, 'id');
  const relatedCompanyBalanceLinks = useCollectionSubscription(company.ref.collection('relatedCompanyBalanceLinks').where('yearMonth', '==', yearMonth), [company, yearMonth]);
  const consolidationJournals = useCollectionSubscription(company.ref.collection('consolidationJournals').where('yearMonth', '==', yearMonth), [company, yearMonth]);
  const consolidationJournalLinkIds = consolidationJournals.map(_ => _.linkId);
  const sections = useCollectionSubscription(company.ref.collection('sections').where('subsidiaryId', '==', subsidiaryId), [subsidiaryId]);
  const consolidationSegments = useCollectionSubscription(company.ref.collection('consolidationSegments'), [company]);
  const allSections = [...sections, noneSection(relatedCompany, company)];
  const allConsolidationSegments = [...consolidationSegments, generalSegment];
  const consolidationSegmentsById = keyBy(allConsolidationSegments, 'id');
  const sectionsById = keyBy(allSections, 'id');
  const sectionsByName = keyBy(allSections, 'name');
  const rows = generateRows(relatedCompanyBalances || [], relatedCompanyBalanceLinks, consolidationJournals, relatedCompanies, accountItems, sections);
  const processRows = (rows) => {
    return rows.filter(_ => _.opponentCompanyName).map((row) => {
      const { opponentCompanyName, accountItemCode, accountItemName, sectionName, amount, remoteAmount, note, } = row;
      return {
        opponentCompanyId: relatedCompaniesByName[opponentCompanyName]?.id,
        accountItemId: (['bugyo', 'mf'].includes(relatedCompany.externalType) ? accountItemsByCode[accountItemCode]?.id : accountItemsByName[accountItemName]?.id),
        sectionId: sectionsByName[sectionName]?.id || null,
        amount: numeral(amount).value(),
        remoteAmount: numeral(remoteAmount).value(),
        note,
      };
    });
  };
  const processRow = (batch, row, i, importRef) => {
    const ref = relatedCompanyBalancesRef.doc();
    batch.set(ref, {
      ...row,
      subsidiaryId,
      period,
      yearMonth,
      importId: importRef.id,
      createdAt: new Date(),
    }, { merge: true });
  };
  const onSubmitBatchClone = async (values, { onClickClose }) => {
    if(!window.confirm('本当にコピーしますか？')) return;

    const { yearMonth: sourceYearMonth, } = values;
    const sources = await getCollectionData(relatedCompanyBalancesRef.where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', sourceYearMonth));
    await batch(db, sources, (batch, source) => batch.set(relatedCompanyBalancesRef.doc(), { ...omit(source, ['id', 'ref']), subsidiaryId, period, yearMonth, createdAt: new Date() }));
    toast.success('コピーしました');
    onClickClose();
  };
  const beforeDeleteImport = async (_import) => {
    const data = await getCollectionData(relatedCompanyBalancesRef.where('importId', '==', _import.id));
    await batch(db, data, (batch, _) => batch.delete(_.ref));
  };

  return props.translate(
    <div className="company-relatedCompanyBalances">
      <div className="mb-3 d-flex justify-content-end gap-1">
        {
          relatedCompany.externalType !== null && (
            <ModalButton
              label="関係会社取引先データを取り込む"
              modalProps={{ style: { minWidth: 1200 } }}
              content={_ => (
                <RelatedPartnersModalContent {...{ user, relatedCompanyBalances: rows, company, relatedCompanies, relatedCompany, subsidiaryId, period, yearMonth, accountItems, sectionsByName, consolidationSegmentsById, }} />
              )}
              disabled={isLockedMonth}
            />
          )
        }
        <ModalButton label="他の年月からコピー" Modal={ModelFormModal} modalProps={{ values: { period, yearMonth, }, fields: batchCloneFields({ periodOptions, company, fiscalYearOfPeriod, }), title: '他の年月からコピー', onSubmit: onSubmitBatchClone, submitLabel: 'コピー', }} disabled={isLockedMonth} />
        <ImportButton processRows={processRows} processRow={processRow} fields={_ => fields({ companies: relatedCompanies.filter(_ => _ !== relatedCompany), accountItems, otherItems: [...rows, ..._.rows.filter(r => r !== _.row)], })} disabled={isLockedMonth} beforeDeleteImport={beforeDeleteImport} importKey={`relatedCompanyBalances__${relatedCompany.id}__${yearMonth}`} deleteImportConfirmMessage="インポートされた関係会社債権債務・取引高も削除されます。よろしいですか？" documentName="relatedCompanyBalance" />
        <ExportButton fileName="関係会社債権債務・取引高.csv" rows={rowsForExport(company, relatedCompanyBalances, relatedCompanyBalanceLinks, consolidationJournals, relatedCompanies, accountItems, sections)} />
        <AddButton itemRef={relatedCompanyBalancesRef.doc()} processValues={_ => ({ ..._, subsidiaryId, period, yearMonth, })} FormModal={ModelFormModal} formProps={{ title: '関係会社債権債務・取引高 追加', documentName: 'relatedCompanyBalance', fields: fields({ companies: relatedCompanies.filter(_ => _ !== relatedCompany), accountItems, sections, otherItems: rows, }), }} disabled={isLockedMonth} />
      </div>
      <div>
        <table className="table sticky-table">
          <thead className="thead-light text-center">
            <tr>
              <th style={{ minWidth: 200 }}>相手会社</th>
              <th style={{ minWidth: 200 }}>個別勘定科目</th>
              <th style={{ minWidth: 200 }}>部門・セグメント</th>
              <th style={{ minWidth: 200 }}>金額</th>
              <th style={{ minWidth: 200 }}>現地通貨金額</th>
              <th style={{ minWidth: 250 }}>備考</th>
              <th style={{ minWidth: 100 }}></th>
            </tr>
          </thead>
          <tbody>
            {
              rows.map((relatedCompanyBalance) => {
                const { id, ref, opponentCompany, accountItem, amount, exchangedAmounts, remoteAmount, note, relatedCompanyBalanceLink, hasLink, hasConsolicationJournal, importedFromFreeeAt, importedFromExternalAt, trialStartDate, trialEndDate, sectionId, isNotInExternal = false, } = relatedCompanyBalance;
                const category = getCategory(accountItem);
                const section = sectionsById[sectionId];
                const consolidationSegment = consolidationSegmentsById[section?.consolidationSegmentId];

                return (
                  <tr key={id} data-id={id}>
                    <td>
                      {opponentCompany?.display_name}
                    </td>
                    <td>
                      {accountItem?.name}
                    </td>
                    <td>
                      <div>{section?.name}</div>
                      <div>{consolidationSegment?.name}</div>
                    </td>
                    <td className="text-right">
                      <AmountWithExchange currency={relatedCompany.currency} amounts={[amount, exchangedAmounts.amount]} />
                    </td>
                    <td className="text-right">
                      {
                        remoteAmount != null && floatFormat(remoteAmount)
                      }
                    </td>
                    <td>
                      {isNotInExternal && <div><div className="badge badge-danger">{externalTypes[relatedCompany.externalType || 'freee']?.label}に存在しません</div></div>}
                      {
                        (importedFromFreeeAt || importedFromExternalAt) && (
                          <div className="text-nowrap text-muted small">
                            {formatDate((importedFromFreeeAt || importedFromExternalAt).toDate(), 'yyyy/MM/d HH:mm:ss')}に取込({[trialStartDate, trialEndDate].map(_ => _?.slice(0, 7).replace('-', '/')).join(' - ')}試算表)
                          </div>
                        )
                      }
                      <AutoLinkText>
                        {note}
                      </AutoLinkText>
                    </td>
                    <td className="text-nowrap text-right">
                      <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '関係会社債権債務・取引高 編集', documentName: 'relatedCompanyBalance', fields: fields({ companies: relatedCompanies.filter(_ => _ !== relatedCompany), accountItems, sections, otherItems: rows.filter(_ => _.id !== id), }), }} disabled={hasConsolicationJournal || isLockedMonth} />
                      <DeleteButton item={relatedCompanyBalance} itemRef={ref} className="ml-2" disabled={hasLink || isLockedMonth} />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});

function RelatedPartnersModalContent (props) {
  const { user, relatedCompanyBalances, company, relatedCompanies, relatedCompany, subsidiaryId, period, yearMonth, accountItems, sectionsByName, consolidationSegmentsById, } = props;
  const sections = useCollectionSubscription(company.ref.collection('sections').where('subsidiaryId', '==', subsidiaryId), [subsidiaryId]);
  const hasConsolicationJournal = relatedCompanyBalances.some(_ => _.hasConsolicationJournal);
  const [selectedStartPeriod, selectStartPeriod] = useDependentState(period, [period]);
  const [selectedStartYearMonth, selectStartYearMonth] = useDependentState(fiscalYearOfPeriod(period, relatedCompany.fiscalYears)?.start_date.replace('-', '').slice(0, 6), [period]);
  const [selectedEndPeriod, selectEndPeriod] = useDependentState(period, [period]);
  const [selectedEndYearMonth, selectEndYearMonth] = useDependentState(yearMonth, [yearMonth]);
  const relatedCompanyBalancesByKey = keyBy(relatedCompanyBalances, _ => [_.opponentCompanyId, _.accountItemId, _.sectionId].filter(_ => _).join('__'));
  const filteredAccountItems = accountItems.filter(_ => _.walletable_id == null && !['仮受消費税', '仮払消費税', '関係会社株式', '関係会社出資金', '子会社株式', '資本金', '資本準備金'].includes(_.name));
  const filteredAccountItemsByName = keyBy(filteredAccountItems, 'name');
  const filteredAccountItemsByCode = keyBy(filteredAccountItems, 'shortcut_num');
  const otherRelatedCompanies = relatedCompanies.filter(_ => _.id !== relatedCompany.id);
  const periodOptions = (relatedCompany.fiscalYears || []).map(_ => ({ label: `${_.start_date.replace(/-/g, '/').slice(0, 7)} - ${_.end_date.replace(/-/g, '/').slice(0, 7)}`, value: periodOfFiscalYear(_), }));
  const startFiscalYear = fiscalYearOfPeriod(selectedStartPeriod, relatedCompany.fiscalYears) || {};
  const endFiscalYear = fiscalYearOfPeriod(selectedEndPeriod, relatedCompany.fiscalYears) || {};
  const usesIndustry = ['bugyo', 'mf'].includes(relatedCompany.externalType) || !!endFiscalYear?.use_industry_template;
  const enabledDocumentTypes = keys(usesIndustry ? documentTypes : omit(documentTypes, 'cr'));
  const [currentDocumentTypes, setCurrentDocumentTypes] = useState(enabledDocumentTypes);
  const startMonthOptions = startFiscalYear.start_date ? range(formatDate(dateStringToDate(startFiscalYear.start_date), 'yyyyMM'), formatDate(dateStringToDate(startFiscalYear.end_date), 'yyyyMM') + '.1').filter(_ => inRange(parseInt(_.toString().slice(-2), 10), 1, 12.1)).map(_ => ({ label: `${_.toString().slice(0, 4)}/${_.toString().slice(4)}`, value: _.toString() })) : [];
  const endMonthOptions = endFiscalYear.start_date ? range(formatDate(dateStringToDate(endFiscalYear.start_date), 'yyyyMM'), formatDate(dateStringToDate(endFiscalYear.end_date), 'yyyyMM') + '.1').filter(_ => inRange(parseInt(_.toString().slice(-2), 10), 1, 12.1)).map(_ => ({ label: `${_.toString().slice(0, 4)}/${_.toString().slice(4)}`, value: _.toString() })) : [];
  const onClickProcess = async () => {
    if(!window.confirm('関係会社取引先データを取り込みます。よろしいですか？')) return;

    try {
      const startDate = formatDate(startOfMonth(parseDate(selectedStartYearMonth + '01', 'yyyyMMdd', new Date())), 'yyyy-MM-dd');
      const endDate = formatDate(endOfMonth(parseDate(selectedEndYearMonth + '01', 'yyyyMMdd', new Date())), 'yyyy-MM-dd');
      const filteredFiscalYears = relatedCompany.fiscalYears.filter(_ => _.end_date >= startDate).filter(_ => _.start_date <= endDate);
      const dateRanges = filteredFiscalYears
        .map(_ => ({ dataAreaName: _.Name, startDate: last(sortBy([_.start_date, startDate], _ => _)), endDate: first(sortBy([_.end_date, endDate])), }));
      const targetRelatedCompanies = otherRelatedCompanies.filter(_ => relatedCompany.relatedCompanyPartnerSettings?.[_.id]);
      const items = await ({
        freee: async () => {
          const trials = await targetRelatedCompanies.flatMap(otherRelatedCompany => currentDocumentTypes.filter(_ => enabledDocumentTypes.includes(_)).map(_ => ({ otherRelatedCompany, type: _, }))).reduce(async (x, { otherRelatedCompany, type, }) => {
            const prevs = await x;

            const partnerIds = keys(getExtraPartnerValues(relatedCompany, otherRelatedCompany.id));
            const trials = await Promise.all(partnerIds.map(async (partnerId) => {
              const { data } = await fetchTrial({
                type,
                externalType: relatedCompany.externalType,
                companyId: company.id,
                companySourceId: relatedCompany.sourceId || company.sourceId,
                ownerId: findKey(company.users, { role: 'owner' }),
                subsidiaryId,
                startDate,
                endDate,
                yearMonth,
                dateRanges,
                params: {
                  partner_id: partnerId,
                  breakdown_display_type: 'section',
                },
              });
              return data;
            }));
            return [...prevs, { type, otherRelatedCompany, trials, }];
          }, Promise.resolve([]));
          const items = trials.flatMap(({ type, otherRelatedCompany, trials }) => {
            const plOrCr = () => trials.flatMap(_ => _.balances.flatMap((balance) => {
              return (balance.sections || []).map((subBalance) => {
                const section = sectionsByName[subBalance.name];
                return {
                  opponentCompanyId: otherRelatedCompany.id,
                  accountItemId: filteredAccountItemsByName[balance.account_item_name]?.id,
                  amount: subBalance.closing_balance,
                  remoteAmount: null,
                  ...(relatedCompany.externalType === 'freee' && { importedFromFreeeAt: new Date(), }),
                  importedFromExternalAt: new Date(),
                  trialStartDate: startDate,
                  trialEndDate: endDate,
                  sectionId: section?.id || null,
                };
              });
            }));
            return ({
              bs: () => trials.flatMap(_ => _.balances.map((balance) => {
                return {
                  opponentCompanyId: otherRelatedCompany.id,
                  accountItemId: filteredAccountItemsByName[balance.account_item_name]?.id,
                  amount: balance.closing_balance,
                  remoteAmount: null,
                  [relatedCompany.externalType === 'freee' ? 'importedFromFreeeAt' : 'importedFromExternalAt']: new Date(),
                  trialStartDate: startDate,
                  trialEndDate: endDate,
                  sectionId: null,
                };
              })),
              pl: plOrCr,
              cr: plOrCr,
            })[type]();
          }).filter(_ => _.accountItemId != null && Math.abs(_.amount) > 0);
          const itemsGroupedByKey = groupBy(items, _ => [_.opponentCompanyId, _.accountItemId, _.sectionId].join('__'));
          return Object.values(itemsGroupedByKey).map(_ => ({ ..._[0], amount: sumBy(_, 'amount'), }));
        },
        bugyo: async () => {
          const sectionTrials = await sections.reduce(async (x, section) => {
            const prevs = await x;

            const { data } = await fetchTrial({
              externalType: 'bugyo',
              companyId: company.id,
              companySourceId: relatedCompany.sourceId || company.sourceId,
              ownerId: findKey(company.users, { role: 'owner' }),
              subsidiaryId,
              startDate,
              endDate,
              yearMonth,
              dateRanges,
              params: {
                aggregationTargetList: [
                  { itemKey: 'GL4021002', },
                  { itemKey: 'GL4021005', }, // 取引先別
                ],
                narrowCondition: [{
                  itemKey: 'GL4022001', // 部門
                  startCode: section.code,
                  endCode: section.code,
                }]
              },
            });
            console.log(data);
            return [...prevs, { section, trial: data, }];
          }, Promise.resolve([]));
          const partnerSectionBalances = sectionTrials.flatMap(st => st.trial.balances.flatMap(b => b.partners.map(_ => ({ ...b, ..._, section: st.section }))));
          const partnerSectionBalancesGroupedByAccountItem = groupBy(partnerSectionBalances, _ => [_.account_category_name, _.account_item_name].join('__'));
          const items = Object.values(partnerSectionBalancesGroupedByAccountItem).flatMap((partnerSectionBalances) => {
            const [{ account_item_name, account_category_name }] = partnerSectionBalances;
            const category = accountItemCategoriesByName[account_category_name];
            return targetRelatedCompanies.flatMap((otherRelatedCompany) => {
              const partnerIds = keys(getExtraPartnerValues(relatedCompany, otherRelatedCompany.id));
              const balances = partnerSectionBalances.filter(_ => partnerIds.includes(_.id));
              const plOrCr = () => {
                return entries(groupBy(balances, 'section.id')).map(([sectionId, balances]) => {
                  const [{ section }] = balances;
                  return {
                    opponentCompanyId: otherRelatedCompany.id,
                    accountItemId: filteredAccountItemsByName[account_item_name]?.id,
                    amount: sumBy(balances, 'closing_balance'),
                    remoteAmount: null,
                    ...(relatedCompany.externalType === 'freee' && { importedFromFreeeAt: new Date(), }),
                    importedFromExternalAt: new Date(),
                    trialStartDate: startDate,
                    trialEndDate: endDate,
                    sectionId: section?.id || null,
                  };
                });
              };
              return ({
                bs: () => {
                  return [{
                    opponentCompanyId: otherRelatedCompany.id,
                    accountItemId: filteredAccountItemsByName[account_item_name]?.id,
                    amount: sumBy(balances, 'closing_balance'),
                    remoteAmount: null,
                    [relatedCompany.externalType === 'freee' ? 'importedFromFreeeAt' : 'importedFromExternalAt']: new Date(),
                    trialStartDate: startDate,
                    trialEndDate: endDate,
                    sectionId: null,
                  }];
                },
                pl: plOrCr,
                cr: plOrCr,
              })[category.type]();
            });
          }).filter(_ => _.accountItemId != null && Math.abs(_.amount) > 0);
          return items;
        },
        mf: async () => {
          return (await serial(currentDocumentTypes, async (type) => {
            return (await Promise.all(targetRelatedCompanies.map(async (otherRelatedCompany) => {
              const partnerIds = keys(getExtraPartnerValues(relatedCompany, otherRelatedCompany.id));
              if(isEmpty(partnerIds)) return [];

              // TODO
              const partnerId = partnerIds[0];
              const { data: balances, } = await fetchMfPartnerSectionsTrials({
                type,
                companyId: company.id,
                subsidiaryId,
                startDate,
                endDate,
                yearMonth,
                dateRanges,
                partnerId,
              });
              return balances
                .filter(_ => !['繰越利益剰余金', '現金・預金'].includes(_.account_category_name))
                .flatMap((item) => {
                  const { account_item_name, account_category_name, sections: subBalances, } = item;
                  const category = accountItemCategoriesByName[account_category_name];
                  const plOrCr = () => {
                    return subBalances.map((subBalance) => {
                      return {
                        opponentCompanyId: otherRelatedCompany.id,
                        accountItemId: filteredAccountItemsByName[account_item_name]?.id,
                        amount: subBalance.closing_balance,
                        remoteAmount: null,
                        ...(relatedCompany.externalType === 'freee' && { importedFromFreeeAt: new Date(), }),
                        importedFromExternalAt: new Date(),
                        trialStartDate: startDate,
                        trialEndDate: endDate,
                        sectionId: subBalance.id || null,
                      };
                    });
                  };
                  return ({
                    bs: () => {
                      return [{
                        opponentCompanyId: otherRelatedCompany.id,
                        accountItemId: filteredAccountItemsByName[account_item_name]?.id,
                        amount: sumBy(subBalances, 'closing_balance'),
                        remoteAmount: null,
                        [relatedCompany.externalType === 'freee' ? 'importedFromFreeeAt' : 'importedFromExternalAt']: new Date(),
                        trialStartDate: startDate,
                        trialEndDate: endDate,
                        sectionId: null,
                      }];
                    },
                    pl: plOrCr,
                    cr: plOrCr,
                  })[category.type]();
                });
            }))).flat().filter(_ => _.accountItemId != null && Math.abs(_.amount) > 0);
          })).flat();
        },
        pca: async () => {
          const partnerIds = targetRelatedCompanies.flatMap(_ => keys(getExtraPartnerValues(relatedCompany, _.id)));
          const { data: sectionBalancesByHojo } = await fetchPcaSectionBalancesByHojo({
            companyId: company.id,
            subsidiaryId,
            startDate,
            endDate,
            yearMonth,
            dateRanges,
            hojoIds: partnerIds,
          });
          return targetRelatedCompanies.flatMap((otherRelatedCompany) => {
            const partnerIds = keys(getExtraPartnerValues(relatedCompany, otherRelatedCompany.id));
            const items = partnerIds.flatMap(_ => entries(sectionBalancesByHojo[_] || {}).flatMap(([accountItemCode, sectionBalances]) => {
              const accountItem = filteredAccountItemsByCode[accountItemCode];
              const [{ type }] = sectionBalances;
              const plOrCr = () => {
                return sectionBalances.map((sectionBalance) => {
                  return {
                    opponentCompanyId: otherRelatedCompany.id,
                    accountItemId: accountItem.id,
                    amount: sectionBalance.closing_balance,
                    remoteAmount: null,
                    ...(relatedCompany.externalType === 'freee' && { importedFromFreeeAt: new Date(), }),
                    importedFromExternalAt: new Date(),
                    trialStartDate: startDate,
                    trialEndDate: endDate,
                    sectionId: sectionBalance.sectionId || null,
                  };
                });
              };
              return ({
                bs: () => {
                  return [{
                    opponentCompanyId: otherRelatedCompany.id,
                    accountItemId: accountItem.id,
                    amount: sumBy(sectionBalances, 'closing_balance'),
                    remoteAmount: null,
                    [relatedCompany.externalType === 'freee' ? 'importedFromFreeeAt' : 'importedFromExternalAt']: new Date(),
                    trialStartDate: startDate,
                    trialEndDate: endDate,
                    sectionId: null,
                  }];
                },
                pl: plOrCr,
                cr: plOrCr,
              })[type]();
            })).filter(_ => _.accountItemId != null && Math.abs(_.amount) > 0);
            const itemsGroupedByKey = groupBy(items, _ => [_.opponentCompanyId, _.accountItemId, _.sectionId].join('__'));
            return Object.values(itemsGroupedByKey).map(_ => ({ ..._[0], amount: sumBy(_, 'amount'), }));
          });
        },
      })[relatedCompany.externalType || 'freee']();
      const existenceCheckedItems = items.map(_ => ({ ..._, existingItem: relatedCompanyBalancesByKey[[_.opponentCompanyId, _.accountItemId, _.sectionId].filter(_ => _).join('__')], }));
      await batch(db, existenceCheckedItems, (batch, item) => {
        const { existingItem } = item;
        const ref = existingItem?.ref || company.ref.collection('relatedCompanyBalances').doc();
        batch.set(ref, {
          ...omit(item, 'existingItem'),
          ...(
            existingItem == null && {
              note: '',
              subsidiaryId,
              period,
              yearMonth,
              createdAt: new Date(),
            }
          ),
        }, { merge: true });
      });
      const survivalExistingItemIds = existenceCheckedItems.map(_ => _.existingItem).filter(_ => _).map(_ => _.id);
      await batch(db, relatedCompanyBalances.filter(_ => _.importedFromExternalAt || _.importedFromFreeeAt).filter(_ => !survivalExistingItemIds.includes(_.id)), (batch, _) => batch.update(_.ref, { isNotInExternal: true, }));
      toast.success('取り込みました');
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const rawRows = async () => {
    const startDate = formatDate(startOfMonth(parseDate(selectedStartYearMonth + '01', 'yyyyMMdd', new Date())), 'yyyy-MM-dd');
    const endDate = formatDate(endOfMonth(parseDate(selectedEndYearMonth + '01', 'yyyyMMdd', new Date())), 'yyyy-MM-dd');
    const filteredFiscalYears = relatedCompany.fiscalYears.filter(_ => _.end_date >= startDate).filter(_ => _.start_date <= endDate);
    const dateRanges = filteredFiscalYears
      .map(_ => ({ startDate: last(sortBy([_.start_date, startDate], _ => _)), endDate: first(sortBy([_.end_date, endDate])), }));
    const results = await sections.reduce(async (x, section) => {
      const prevs = await x;

      const result = await fetchRawTrials({
        url: '/gl/ExportAccountingReport',
        externalType: 'bugyo',
        companyId: company.id,
        companySourceId: relatedCompany.sourceId || company.sourceId,
        ownerId: findKey(company.users, { role: 'owner' }),
        subsidiaryId,
        startDate,
        endDate,
        yearMonth,
        dateRanges,
        params: {
          itemList: keys(invertedReportCodes),
          aggregationTargetList: [
            { itemKey: 'GL4021002', },
            { itemKey: 'GL4021005', }, // 取引先別
          ],
          narrowCondition: [{
            itemKey: 'GL4022001', // 部門
            startCode: section.code,
            endCode: section.code,
          }]
        },
      });
      return [...prevs, { section, result, }];
    }, Promise.resolve([]));
    return results.flatMap(({ section, result }) => {
      return result.data.flatMap(({ balances, startDate, endDate }) => {
        return (balances || []).map(_ => ({ sectionId: section.id, sectionName: section.name, startDate, endDate, ..._, }));
      });
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-start align-items-end gap-1">
        <div className="d-flex gap-1 align-items-end">
          <div className="d-flex gap-1 align-items-center">
            <div>開始</div>
            <div style={{ width: 160, position: 'relative', zIndex: 5 }}>
              <Select
                value={periodOptions.find(_ => _.value === selectedStartPeriod)}
                options={periodOptions}
                onChange={_ => selectStartPeriod(_.value)}
                components={{ IndicatorsContainer: _ => null }}
                className="w-100"
              />
            </div>
            <div style={{ width: 90, position: 'relative', zIndex: 5 }}>
              <Select
                value={startMonthOptions.find(_ => _.value === selectedStartYearMonth) || null}
                options={startMonthOptions}
                onChange={_ => selectStartYearMonth(_.value)}
                components={{ IndicatorsContainer: _ => null }}
                className="w-100"
              />
            </div>
          </div>
          <div className="d-flex gap-1 align-items-center">
            <div>終了</div>
            <div style={{ width: 160, position: 'relative', zIndex: 5 }}>
              <Select
                value={periodOptions.find(_ => _.value === selectedEndPeriod)}
                options={periodOptions}
                onChange={_ => selectEndPeriod(_.value)}
                components={{ IndicatorsContainer: _ => null }}
                className="w-100"
              />
            </div>
            <div style={{ width: 90, position: 'relative', zIndex: 5 }}>
              <Select
                value={endMonthOptions.find(_ => _.value === selectedEndYearMonth) || null}
                options={endMonthOptions}
                onChange={_ => selectEndYearMonth(_.value)}
                components={{ IndicatorsContainer: _ => null }}
                className="w-100"
              />
            </div>
          </div>
        </div>
        <div>
          {
            relatedCompany.externalType === 'freee' && entries(pick(documentTypes, enabledDocumentTypes)).map(([documentType, { label }]) => {
              const checked = currentDocumentTypes.includes(documentType);
              return (
                <FormGroup check key={documentType}>
                  <Label check>
                    <Input type="checkbox" checked={checked} onChange={_ => _.target.checked ? setCurrentDocumentTypes([...currentDocumentTypes, documentType]) : setCurrentDocumentTypes(currentDocumentTypes.filter(_ => _ !== documentType))} />
                    {label}
                  </Label>
                </FormGroup>
              );
            })
          }
        </div>
        <ProgressButton process={onClickProcess} disabled={!(selectedStartPeriod && selectedStartYearMonth && selectedEndPeriod && selectedEndYearMonth) || hasConsolicationJournal}>
          関係会社取引先データを取り込む
        </ProgressButton>
        {
          user.admin && relatedCompany.externalType === 'bugyo' && (
            <ExportButton fileName="raw_trials.csv" label="raw trials" rows={rawRows} />
          )
        }
      </div>
      {
        hasConsolicationJournal && (
          <div className="mt-1 text-warning">
            関連仕訳が存在するため取り込めません。
          </div>
        )
      }
      <div className="mt-4 card p-2">
        <h5>関係会社取引先 設定</h5>
        <div className="d-flex justify-content-end gap-1">
          <div style={{ width: 200 }}>
          </div>
        </div>
        <div className="mt-3">
          <table className="table table-borderless table-sm">
            <tbody>
              {
                otherRelatedCompanies.map((otherRelatedCompany) => {
                  const { id, } = otherRelatedCompany;
                  return (
                    <RelatedCompanyPartnerSettingRow key={id} {...{ company, subsidiaryId, relatedCompany, otherRelatedCompany }} />
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function RelatedCompanyPartnerSettingRow (props) {
  const { company, subsidiaryId, relatedCompany, otherRelatedCompany } = props;
  const { display_name, } = otherRelatedCompany;
  const [externalPartnerId, setExternalPartnerId] = useState();
  const [externalPartners, setExternalPartners] = useState([]);
  const loadOptions = debounce(async (query, cb) => {
    const { data: externalPartners } = await ({
      freee: searchFreeePartners,
      bugyo: searchBugyoPartners,
      pca: searchPcaPartners,
    })[relatedCompany.externalType]({ companyId: company.id, subsidiaryId, companySourceId: relatedCompany.sourceId || company.sourceId, ownerId: findKey(company.users, { role: 'owner' }), query, });
    setExternalPartners(externalPartners);
    cb(externalPartners.map(_ => ({ label: _.name, value: _.id })));
  }, 500);
  const partnerChunks = useCollectionSubscription(company.ref.collection('partnerChunks').where('subsidiaryId', '==', subsidiaryId), [company]);
  const partners = partnerChunks.flatMap(_ => _.partners);
  const mfPartnerOptions = partners.map(_ => ({ label: _.name, value: _.sourceId, }));
  const onFix = async () => {
    await relatedCompany.ref.set({
      relatedCompanyPartnerSettings: {
        [otherRelatedCompany.id]: {
          externalPartnerValues: {
            [externalPartnerId]: {
              ...pick([...externalPartners, ...partners].find(_ => _.id === externalPartnerId), 'name'),
            },
          },
        },
      },
    }, { merge: true });
    toast.success('保存しました');
    setExternalPartners([]);
    setExternalPartnerId(null);
  };

  return (
    <tr>
      <td>
        {display_name}
      </td>
      <td>
        {
          entries(getExtraPartnerValues(relatedCompany, otherRelatedCompany.id)).map(([externalPartnerId, { name }]) => {
            const remove = async () => {
              if(!window.confirm('関連付けを解除します。よろしいですか？')) return;

              await relatedCompany.ref.update({
                relatedCompanyPartnerSettings: {
                  ...relatedCompany.relatedCompanyPartnerSettings,
                  [otherRelatedCompany.id]: {
                    externalPartnerValues: omit(getExtraPartnerValues(relatedCompany, otherRelatedCompany.id), externalPartnerId),
                  },
                },
              });
            };
            return (
              <div key={externalPartnerId}>
                <span>{name}</span>
                <ProgressButton className="ml-1 text-danger" color="link" outline size="sm" process={remove}>
                  <span className="fas fa-times" />
                </ProgressButton>
              </div>
            );
          })
        }
      </td>
      <td>
        <div className="d-flex justify-content-end gap-1">
          <div style={{ width: 200 }}>
            {
              ['freee', 'bugyo', 'pca'].includes(relatedCompany.externalType) ? (
                <AsyncSelect
                  className="w-100"
                  placeholder={({ freee: '検索キーワード', bugyo: '検索キーワード', pca: 'コード' })[relatedCompany.externalType]}
                  loadOptions={loadOptions}
                  onChange={_ => _ && setExternalPartnerId(_.value)}
                />
              ) : (
                <Select
                  className="w-100"
                  options={mfPartnerOptions}
                  onChange={_ => _ && setExternalPartnerId(_.value)}
                />
              )
            }
          </div>
          <ProgressButton process={onFix} disabled={!externalPartnerId}>
            決定
          </ProgressButton>
        </div>
      </td>
    </tr>
  );
}
